import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LinearProgress } from '@material-ui/core';
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';

const ThankyouView = (props) => {

    const detailList = [
        {
          icon: <AssignmentIcon sx={{fontSize: '40px'}}/>,
          title: 'Branch',
          detail: props.branch.name
        },
        {
          icon: <LocationOnIcon sx={{fontSize: '40px'}}/>,
          title: 'Address',
          detail: props.branch.address
        },
        {
          icon: <AccessTimeFilledIcon sx={{fontSize: '40px'}}/>,
          title: 'Date/Time',
          detail: props.bookingDetails.bookingTime
        },
        {
          icon: <AccountCircleIcon sx={{fontSize: '40px'}}/>,
          title: 'Practioner',
          detail: props.bookingDetails.doctorName
        }
    ]


    if (props.success) {
        return (
          <motion.div animate={{ height: 'auto' }} initial={{ height: 0 }} transition={{ ease: "easeInOut", duration: 1 }} style={{ overflow: 'hidden' }}>
            <div className='book-consultation-detail-content' style={{ textAlign: 'center', paddingBottom: '20px' }}>
              <div className='book-consultation-detail-detail'>
                <div className='title-large'>Your Booking is Confirmed</div>
                <div className='title'>We look forward to seeing you for your appointment</div>
              </div>
              <div className="booking-confirmation-wrapper">
                <Grid container>
                  {detailList.map((item, index) => { 
                    return (
                        <Grid item key={index} xs={12} md={6} sx={{ textAlign: 'center', padding: '20px' }}>
                          <div className="detail-item-icon">{item.icon}</div>
                          <div className="detail-item-title">{item.title}</div>
                          <div className="detail-item-text">{item.detail}</div>
                        </Grid>
                    )
                  })}
                </Grid>
              </div>
              <div className="detail-item-text" style={{ marginTop: '20px', marginBottom: '20px' }}>
                You will receive confirmation prior to your appointment and please contact us on <b>{props.branch.phone}</b> with any questions prior.
              </div>
            </div>
          </motion.div>
        )
      } else {
        return (
          <div className='book-consultation-detail-content' style={{ textAlign: 'center', paddingBottom: '20px' }}>
            { props.loading ? 
              <div className='book-consultation-detail-detail'>
                <LinearProgress/>
              </div> 
              :
              <div className='book-consultation-detail-detail'>
                <div className='title-large'>Something went wrong</div>
                <div className='title'>Please call {props.branch.phone} to make your booking</div>
              </div> 
            }
          </div>
        )
      }
}

export default ThankyouView;