import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

/**
 * Renders a wrapper component for the appointment booking steps on desktop view.
 * @param {Object} props - The props object containing the step number.
 * @returns {JSX.Element} - The JSX element representing the StepsWrapperDesktop component.
 */
const StepsWrapperDesktop = (props) => {
  return (
    <div className='appointment-step-container'>
      <div className='appointment-step-wrapper'>
        <div className={`appointment-step-container-step ${props.step === 0 ? "selected" : ""}`}>
          {props.step === 0 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
          <div className='text'>Find Appointment</div>
        </div>
        <div className={`appointment-step-container-step ${props.step === 1 ? "selected" : ""}`}>
          {props.step === 1 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
          <div className='text'>Client Details</div>
        </div>
        <div className={`appointment-step-container-step ${props.step === 2 ? "selected" : ""}`}>
          {props.step === 2 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
          <div className='text'>Confirmation</div>
        </div>
      </div>
    </div>
  )
}

/**
 * A component that displays the steps of the appointment booking process on mobile devices.
 * @param {Object} props - The props object that contains the step number.
 * @returns {JSX.Element} - The JSX element that displays the steps.
 */
const StepsWrapperMobile = (props) => {
    return (
    <div className='appointment-step-container'>
        <div className='appointment-step-wrapper'>
        {(props.step === 0) ? 
        <div className={`appointment-step-container-step ${props.step === 0 ? "selected" : ""}`}>
            {props.step === 0 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
            <div className='text'>Find Appointment</div>
        </div> : ''}
        {(props.step === 1) ? 
        <div className={`appointment-step-container-step ${props.step === 1 ? "selected" : ""}`}>
            {props.step === 1 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
            <div className='text'>Patient Details</div>
        </div> : ''}
        {(props.step === 2) ? 
        <div className={`appointment-step-container-step ${props.step === 2 ? "selected" : ""}`}>
            {props.step === 2 ?
            <CheckCircleOutlineOutlinedIcon className='circle-icon' fontSize='small' />
            : <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
            <div className='text'>Confirmation</div>
        </div> : ''}
        </div>
    </div>
    )
}

/**
 * Renders a wrapper component for the appointment booking steps.
 * @param {Object} props - The props object containing the step number.
 * @returns {JSX.Element} - The JSX element representing the StepsWrapper component.
 */
const StepsWrapper = (props) => {
    return (
        <>
        {props.isMobile ? <StepsWrapperMobile step={props.step} /> : <StepsWrapperDesktop step={props.step} />}
        </>
    )
}

export default StepsWrapper;
