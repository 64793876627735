export const useStyles = (theme) => ({
    selectType: {
      height: "40px",
      background: "#E6E8EC",
      borderRadius: "10px",
      '&.MuiInputBase-root': {
        fontSize: "14px",
      },
      '& legend': {
        display: "none"
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: "0px",
        borderColor: "#E6E8EC",
        borderRadius: "10px"
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#bcc1ca !important"
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#bcc1ca !important"
      }
  
    },
    selectTypeItem: {
      '&.MuiMenuItem-root': {
        fontSize: "14px"
      }
    },
    selectCalendar: {
      '&.MuiPickerStaticWrapper-root': {
        width: "fit-content",
      },
      '&.MuiTypography-root': {
        fontSize: "14px",
      },
    }
  })