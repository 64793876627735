import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import BookingPortal from 'src/BookingPortal.js';
import SelectDataView from './Views/SelectDataView';

let params = new URLSearchParams(window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(    
    <div className='main-container-wrapper-outer' style={{ height: "100%", width: "100%" }}>
      {params.get('site') ? <BookingPortal /> : <SelectDataView/>}
    </div>
);
