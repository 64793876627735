import React from 'react';
import { FormControl, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { motion } from 'framer-motion';

const BookingForm = (props) => {

    return (
        <FormControl sx={{ width: "100%",}}>
            <div className='title'>Please Complete Your Details (*required field)</div>
            <Grid
                container
                component="form"
                spacing={2}
                sx={{
                    '& .MuiTextField-root': { mt: 1, width: '100%', backgroundColor: '#ebf3fa' },
                    '& .MuiInputBase-input': { fontSize: '18px' },
                    '& .MuiInputLabel-root': { fontSize: '16px' },
                    "& .MuiInputLabel-shrink": { color: "gray" },
                    "& .MuiFilledInput-underline:after": { borderBottom: "2px solid #5fc2b6" }
                }}
                noValidate
                autoComplete="off"
            >
                {props.formFields.map((field, index) => {
                    let fieldHtml;

                    if (field.type === 'radio') {
                        fieldHtml = (
                            <Grid 
                                item xs={12} 
                                md={field.size ? field.size : '12'} 
                                key={index} 
                                sx={{ 
                                    display: field.display ? field.display : 'block',
                                }}
                            >
                                <div 
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        color: '#272727'
                                    }}
                                >
                                    {field.label}
                                </div>
                                <RadioGroup
                                    name={field.name}
                                    id={field.name}
                                    key={field.name}
                                    row={true}
                                    onChange={props.formChangeHandler}
                                    sx={{
                                        marginTop: '20px',   
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px',
                                        }, 
                                        '& .MuiRadio-root': {
                                            display: 'none',
                                        },  
                                    }}
                                    value={props.formData[field.name] ? props.formData[field.name] : field.options[0]}
                                >
                                    {field.options.map((option, index) => {
                                        return (
                                            <motion.div whileTap={{ scale: 1.2, opacity: '100%' }} key={option}>
                                                <FormControlLabel
                                                    key={index}
                                                    sx={{
                                                        background: option === props.formData[field.name] ? props.branch.colorScheme.secondary : props.branch.colorScheme.secondarySelected,
                                                        ":hover": {
                                                            opacity: option === props.formData[field.name] ? '100% ' : '80%',
                                                        },
                                                        padding: document.getElementById('appContainer').clientWidth < 480 ? "10px 22px" : option === props.formData[field.name] ? '17px 25px' : '15px 25px',
                                                        margin: option === props.formData[field.name] ? '0px' : '2px 0 0 0',
                                                        color: 'white',
                                                        borderRadius: index === 0 ? '5px 0 0 5px' : index === (field.options.length - 1) ? '0 5px 5px 0' : '0',
                                                    }}
                                                    name={field.name}
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option} />
                                            </motion.div>
                                        )
                                    })}
                                </RadioGroup>
                            </Grid>
                        )  
                    } else if (field.type === 'date') {
                        fieldHtml = (
                            <Grid item xs={12} md={field.size ? field.size : '12'} key={index}>
                                <div style={{marginTop: '20px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={field.label}
                                        value={props.formData[field.name] ? props.formData[field.name] : null}
                                        onChange={props.formChangeHandler}
                                        name={field.name}
                                        id={field.name}
                                        key={field.name}
                                        helperText={props.showErrors ? props.errors[field.name] : ''}
                                        error={(props.errors[field.name] && props.showErrors) ? true : false}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                                </div> 
                            </Grid>
                        )                           
                    } else {
                        let IProps = field.type === 'date' ? {shrink: true} : {};
                        fieldHtml = (
                            <Grid item xs={12} md={field.size ? field.size : 12} sx={{marginTop: '20px'}} key={index}>
                                <TextField
                                    name={field.name}
                                    id={field.name}
                                    key={field.name}
                                    helperText={props.showErrors ? props.errors[field.name] : ''}
                                    error={(props.errors[field.name] && props.showErrors) ? true : false}
                                    type={field.type ? field.type : 'text'}
                                    InputLabelProps={IProps}
                                    value={props.formData[field.name] ? props.formData[field.name] : ''}
                                    label={field.label}
                                    variant="filled"
                                    onChange={props.formChangeHandler}
                                    required />
                            </Grid>
                        )
                    }

                    return(fieldHtml)
                })}
            </Grid>
        </FormControl>
    )
}


export default BookingForm;