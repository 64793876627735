import React from 'react';
import { Grid } from '@mui/material';

/**
* Renders the navigation buttons for the booking portal.
* @returns {JSX.Element} The navigation buttons component.
*/
const NavigationButtons = (props) => {
  return (
    <Grid container sx={{ mt: 5 }}>
      <Grid item xs={5} md={5}>
        <div className='back-button' onClick={() => props.handleWindowChange(false)}><p className='continue-button-text'>Back</p></div>
      </Grid>
      <Grid item xs={7} md={7}>
        <div className={props.formCompleted ? 'continue-button' : 'continue-button-disabled'} onClick={() => { if(props.formCompleted) props.handleWindowChange(true); }}><p className='continue-button-text'>
          {props.buttonValue}</p></div>
      </Grid>
    </Grid>
  )
}

export default NavigationButtons;