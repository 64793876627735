import moment from "moment";

export const nookal = {
    getAppointmentTypes: async function (identifier) {

        let convertedServices = [];
        let result = await this.apiFetch("getAppointmentTypes?page_length=200", true);

        let services = result.data.results.services;
        let maxCount = 20;
        while(result.settings.nextPage != null && maxCount > 0) {
            console.log(maxCount);
            result = await this.apiFetch("getAppointmentTypes?page_length=200&page="+result.settings.nextPage, true);
            services = services.concat(result.data.results.services);
            maxCount--;
        }

        let serviceMatix = await this.apiFetch("getServiceMatrix?location_id="+identifier);
        serviceMatix = serviceMatix.matrix.services;

        let servicesProvided = {
            IDList: [],
        };

        if(new URLSearchParams(window.location.search).get("test")) servicesProvided.IDList = ["1","2","5","7"]

        serviceMatix.map((service) => {
            servicesProvided.IDList.push(service.id);
        });


        Object.keys(services).forEach(function(k){   
            if(services[k].Locations.includes(identifier) && servicesProvided.IDList.includes(services[k].ID)) {

                let providers = [];
                serviceMatix.map((service) => {
                    if(service.id === services[k].ID) {
                        providers = service.providers;
                    }
                });

                if(new URLSearchParams(window.location.search).get("test")) providers = ["1","4","33"];

                convertedServices.push({
                    ID: services[k].ID,
                    description: services[k].Description, 
                    duration: services[k].Duration,
                    providers: providers
                });          
            }
        });
        
        return convertedServices;
    },
    getPractioners: async function (identifier) {

        let convertedPractitioners = [];

        var practitioner = await this.apiFetch("getPractitioners?");
        practitioner = practitioner.practitioners;

        Object.keys(practitioner).forEach(function(k){
            if(practitioner[k].locations.includes(parseInt(identifier))
                || practitioner[k].locations.includes(identifier)
                ) {
                convertedPractitioners.push({
                    ID: practitioner[k].ID,
                    firstName: practitioner[k].FirstName,
                    lastName: practitioner[k].LastName,
                    active: true,
                    speciality: practitioner[k].Speciality,
                    availabilities: [],
                    availabilitiesList: [],
                    services: [],
                    email: practitioner[k].Email
                });
            }
        });

        await Promise.all(convertedPractitioners.map(async(practitioner)=>{

            var getServices = await this.apiFetch("getPractitionerAppointmentTypes?"
                + "&practitioner_id=" + practitioner.ID
                + "&location_id=" + identifier
            );
            practitioner.services = getServices.services;

            var photos = await this.apiFetch("getPractitionerPhoto?practitioner_id=" + practitioner.ID);
    
            if (photos) {
                practitioner.imgSrc = photos.url
            } else {
                practitioner.imgSrc = '/static/images/optometrist/Account.png';
            }
            
        }));

        await Promise.all(convertedPractitioners.map(async(pracitioner)=>{

            var getTimes = await this.apiFetch("getAppointmentAvailabilities?"
                + "&practitioner_id=" + pracitioner.ID
                + "&location_id=" + identifier
                + "&date_from=" + moment().format("YYYY-MM-DD")
                + "&date_to=" + moment().add(6, 'months').format("YYYY-MM-DD"));

            pracitioner.availabilitiesList = getTimes.availabilities;

        }));

        return convertedPractitioners;
    },
    getLocationData: async function (identifier) {
        var result = await this.apiFetch("getLocations?");
        let data = {};

        if(result.locations) {
            result = result.locations
            result.map((location) => {
                let addressLine = "";
                if(location.AddressLine1) {
                    addressLine += location.AddressLine1;
                    if(location.AddressLine2 || location.AddressLine3) addressLine += ", ";
                }
                if(location.AddressLine2) {
                    addressLine += location.AddressLine2;
                    if(location.AddressLine3) addressLine += ", ";
                }
                if(location.AddressLine3) addressLine += location.AddressLine3;
                if(location.ID === identifier) {
                    let locationData = {
                        name: location.Name,
                        address: addressLine,
                        phone: location.Telephone,
                        postcode: location.Postcode,
                        email: location.Email,
                        state: location.State,
                        bulkbill: false,
                        id: identifier
                    }
                    data = locationData;
                }
            });    
        }
        
        return data;
    },
    checkPractitioner: async function(practitioner, currentService) {
        practitioner.availabilities = [];
        Object.keys(practitioner.availabilitiesList).forEach(function (k) {
            let timeIncrement = parseInt(Object.keys(practitioner.availabilitiesList[k])[1].split(":")[1]);
            let hour = "00";
            let minute = "00";
            
            let timesToCheck = currentService.duration/10;
            let validCount = 0;
            let timeToAdd = "";
            while(practitioner.availabilitiesList[k][hour + ":" + minute]) {

                if(practitioner.availabilitiesList[k][hour + ":" + minute] === "available") {
                    if(validCount === 0) timeToAdd = moment(k + " " + hour + ":" + minute).format('YYYY-MM-DD HH:mm');
                    validCount++;
                } else {
                    validCount = 0;
                }

                if(validCount === timesToCheck) {
                    if(moment(k + " " + hour + ":" + minute).isAfter(moment().add(30, 'm'))) {
                        practitioner.availabilities.push(timeToAdd);
                        if(!practitioner.firstAvailable) practitioner.firstAvailable = timeToAdd;
                    }
                    validCount = 0;
                }
                minute = (parseInt(minute) + timeIncrement)
                if(minute >= 60) {
                    minute = "00";
                    hour = parseInt(hour)+1;
                    if(hour < 10) hour = "0" + hour;
                }
                else minute = minute.toString();
            }
        });
    },
    checkPatient: async function(details) {

        let patientDetails = "first_name=" + details.firstName
        + "&last_name=" + details.lastName
        + "&email=" + details.email
        + "&date_of_birth=" + moment(details.DOB.$d).format("YYYY-MM-DD");

        let patient = await this.apiFetch("searchPatients?" + patientDetails);

        if(patient.patients.length === 0) {

            let extendedPatientDetails = patientDetails 
            + "&email=" + details.email
            + "&phone=" + details.phone
            + "&gender=" + details.gender;

            let createdPatient = await this.apiFetch("addPatient?" + extendedPatientDetails, true);
            return createdPatient.data.results.patient_id;
        } else {
            return patient.patients[0].ID;
        }
    },
    apiFetch: async function(modifiers, onlyResults=false) {
        let params = new URLSearchParams(window.location.search);
        let sessionToken = params.get('session_token');

        // test - 99E732E7-524c-b6fc-Dbcd-57AcCBB6Ca3E
        // live - ac8BF6d0-5eab-8cf6-5D2f-d01bE7cEDDcc
        var fetchURL = `/nookal?session_token=${sessionToken}&provider=nookal`;
      
        var requestOptions = {
          method: 'POST',
          body: JSON.stringify({modifiers: modifiers}),
        };
      
        const data = await fetch(fetchURL, requestOptions)
          .then((response) => response.json())
          .then((result) => {

            if(onlyResults) {
                console.log(result);
                return result;
            }

            if(result.data) {
                if(process.env.NODE_ENV === 'development') console.log(result.data.results);
                return result.data.results;
            } else {
                console.log(result.details.alerts ? result.details.alerts : result.status+":", result.details.errorMessage);
                console.log("Modifiers:", modifiers);
                return false;
            }

          })
          .catch(error => console.log('error', error));

        return data;
    },
    postAppointment: async function (details) {
        details.patientID = await this.checkPatient(details.bookingDetails);

        let modifier = "&location_id=" + details.branchIdentifier
        + "&appointment_date=" + details.startdate 
        + "&start_time=" + details.starttime 
        + "&patient_id=" + details.patientID
        + "&practitioner_id=" + details.practitionerID 
        + "&appointment_type_id=" + details.appointmentType;

        let booking = await this.apiFetch("addAppointmentBooking?" + modifier, true);
        return booking.status === 'success' ? true : false;
    },
    sendConfirmMessage: async function (message) {
        var requestOptions = {
            method: 'GET',
        };
        
        await fetch("https://www.backinmotion.com.au/booking_confirmation.php" + message, requestOptions);
    },
    getFormFields: function() { 
        return [
            {name: 'firstName', label: 'First Name', required: true, size: 6},
            {name: 'lastName', label: 'Last Name', required: true, size: 6},
            {name: 'gender', label: 'Gender', required: true, options: ["Male", "Female", "Other"], type: 'radio', size: 6},
            {name: 'DOB', label: 'Date of Birth', required: true, size: 6, type: 'date'},
            {name: 'phone', label: 'Phone Number', required: true, size: 6},
            {name: 'email', label: 'Email', required: true, size: 6,}
        ]
    },
    checkConnection: async function() {
        return await this.apiFetch("getLocations?");
    }
}