import React from 'react';
import NavigationButtons from 'src/components/NavigationButtons';
import BookingDetailsSidebar from 'src/components/BookingDetailsSidebar';
import { Grid } from '@mui/material';
import { motion } from "framer-motion";
import moment from 'moment';

const ConfirmationView = (props) => {

  return (
    <div className='book-consultation-detail-content'>
      <div className='book-consultation-detail-detail'>
        <Grid container>
          <Grid item sm={8} xs={12} sx={{ borderRight: props.isMobile ? "none" : "20px solid white", paddingRight: props.isMobile ? "0" : '40px', paddingBottom: "40px" }}>
            <div className='confirm-detail'>
              <div className='title' style={{margin: '30px 0'}}>Confirm Your Details</div> 
              <Grid container rowSpacing={1}>
                {props.branch.formFields.map((field, index) => {
                  return (
                    <Grid item xs={12} xl={6} key={index}>
                      <motion.div key={index} animate={{ x: 0 }} initial={{ x: -75 }} transition={{ ease: "easeOut", duration: 0.3, delay: 0.05 * index }}  style={{ padding: '0px 5px' }}>  
                        <div style={{pt: 2, backgroundColor: 'white', display: 'flex', borderRadius: '5px', marginTop: '10px', padding: '15px 20px 0 20px'}}>  
                          <div className='confirm-detail-item'>{field.label}:</div>
                          <div className='confirm-detail-value'>{field.name === 'DOB' ? moment(props.bookingDetails['DOB'].$d).format('DD/MM/YYYY') : props.bookingDetails[field.name]}</div>      
                        </div>
                      </motion.div>
                    </Grid>
                  )
                })}
              </Grid>
              <NavigationButtons 
                  formCompleted={true} 
                  buttonValue={'Book Now'} 
                  handleWindowChange={props.handleWindowChange}
                />
            </div>
          </Grid>
          <Grid item sm={4} xs={12} sx={{ paddingLeft: 3 }}>
            <BookingDetailsSidebar branchDetails={props.branch} details={props.bookingDetails} noAnimate={true} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ConfirmationView;