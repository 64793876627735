import React, { useState, useEffect } from 'react';

const dataFields = [
    {name: 'location', options: ['WAT', 'BIM Test', 'Wantirna']},
    {name: 'test', options: ['true', 'false']},
]

const SelectDataView = () => {
    const [selected, setSelected] = useState({});

    useEffect(() => {
        dataFields.map(field => {
            setSelected(prev => ({...prev, [field.name]: ''}))
        })
    }, [])

    const handleSubmit = () => {
        let params = '';
        if(selected['test'] === 'true') params += 'test=1&';
        params += 'site=' + (selected['location'] === 'Wantirna' || selected['location'] === 'BIM Test' ? 'BIM' : 'opticalco') + "&";
        params += 'id=' + (selected['location'] === 'Wantirna' ? 33 : 1) + "&";
        params += 'api_key=' + (selected['location'] === 'Wantirna' ? 'key1' : 'key2');
        window.location.search = params; 
    }

    return (
        <div className="select-view">
            {dataFields.map((field) => {
                return (   
                    <div className="field-row" key={field.name}>
                        {field.options.map(option => {
                            return <div 
                                        key={option} 
                                        className="option-button" 
                                        style={ selected[field.name] === option ? {backgroundColor: 'red'} : {} }
                                        onClick={() => setSelected(prev => ({...prev, [field.name]: option}))}
                                    >
                                        {option}
                                    </div>
                            //<div key={option.name}>{option.name}</div>
                        })}
                    </div>
                );
            })}
            <div className="submit" onClick={handleSubmit}>Submit</div>
        </div>
    )
}

export default SelectDataView;