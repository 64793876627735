import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider} from '@mui/material';
import { motion } from "framer-motion";

/**
 * A functional component that displays the details of a booking in a sidebar.
 * @param {Object} props - The props object containing the details of the booking.
 * @param {Object} props.details - The details of the booking.
 * @param {string} props.details.appointmentTypeDesc - The description of the appointment type.
 * @param {string} props.details.bookingTime - The time of the booking.
 * @param {string} props.details.doctorName - The name of the doctor.
 * @returns {JSX.Element} - The BookingDetailsSidebar component.
 */
const BookingDetailsSidebar = (props) => {

    const tableRows = [
        {icon: <AssignmentIcon />, text: props.details.appointmentTypeDesc ? props.details.appointmentTypeDesc : 'Type'},
        {icon: <AccessTimeFilledIcon />, text: props.details.bookingTime ? props.details.bookingTime : 'Date/Time'},
        {icon: <AccountCircleIcon />, text: props.details.doctorName ? props.details.doctorName : 'Practioner'},
        {icon: <HomeIcon/>, text: props.branchDetails.name},
        {icon: <LocationOnIcon />, text: props.branchDetails.address + " " + props.branchDetails.state + ", " + props.branchDetails.postcode},
    ]

    let animate = props.noAnimate ? { x: 0 } : { x: 100 };

    return (
        <div className="appointment-wrapper">
            <div className='title' style={{marginLeft: '20px'}}>Appointment Details</div>
            <table className="sidebar">
                <tbody>     
                    {tableRows.map((row, index) => {
                        return(
                            <tr key={index}>
                                <td className='left-table-column'>
                                    <motion.div animate={{ x: 0 }} initial={animate} transition={{ ease: "easeInOut", duration: (0.5 + 0.1 * index) }}>
                                        {row.icon}
                                    </motion.div>
                                </td>
                                <td>
                                    <motion.div animate={{ x: 0 }} initial={animate} transition={{ ease: "easeInOut", duration: (0.5 + 0.1 * index) }}>
                                        <div className='detail-item-text'> {row.text}</div>
                                    </motion.div>
                                </td>
                            </tr>
                      )
                    })}
                </tbody>
            </table>
            <Divider sx={{ mt: 2 }} />
            { props.branchDetails.bulkbill ? 
            <div>
                <div className='sidebar-detail'>This location provides bulk billed eye tests subject to Medicare eligibility. Please note bulk billing not available on other optometry services including retinal photography and OCT (costs will apply). Please bring your Medicare card and if applicable private health fund card with you to your appointment.</div>
                <Divider sx={{ mt: 2 }} />
            </div>        
            : ""}
            <div className='book-consultation-detail-detail'>
                <div className='sidebar-detail'>If you would prefer to speak on the phone please call <span style={{ fontWeight: 500 }}>{props.branchDetails.phone}</span></div>
            </div>
            <div>
            <Divider sx={{mt: '30px'}}/>
            <div className='powered-Wrapper'>           
                <p>Powered By</p>
                <div>
                    <img className='logo-small' src="static/images/HealthiaNewLogo2018-Horizontal.png" alt="logo-small"/></div>
                </div>
            </div>
        </div>
    )
}

export default BookingDetailsSidebar;