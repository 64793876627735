import { OpticalCo } from "./clients/OpticalCo";
import { BIM } from "./clients/BIM";
import { Allsports } from "./clients/Allsports";

const clientsMap = {
    opticalco: OpticalCo,
    BIM: BIM,
    allsports: Allsports,
    // Add new clients here
};

export class Branch {
    constructor() {

        this.address = "";
        this.phone = "";
        this.name = "";
        this.postcode = "";
        this.email = "";
        this.state = "";
        this.suburb = "";
        this.bulkbill = false;
        this.appointmentTypes = [];
        this.practioners = [];
        this.customStyles = "";
        this.clientName = "";
        this.formFields = [];
        this.params = new URLSearchParams(window.location.search);

        this.clientName = this.params.get('site');
        if (clientsMap[this.clientName]) {
            this.client = new clientsMap[this.clientName]();
        } else {
            throw new Error(`Client ${this.clientName} not found`);
        }

        this.identifier = this.params.get('id');
        this.colorScheme = this.client.colorScheme();

        // Set the custom styles and color scheme based on the client
        if(this.client) this.customStyles = this.client.customStyles();
        if(this.params.get('test') && document.getElementById('appContainer').clientWidth > 480) 
            this.customStyles += ".book-consultation-detail-wrapper{width: 1000px; margin: 100px auto;} .main-container-wrapper-outer{background:gray;}"
    }

    async getData() {

        const connectionData = await this.client.methods.checkConnection();
        if(connectionData === undefined || connectionData === false) return false;

        this.practioners = await this.client.methods.getPractioners(this.identifier);
        this.appointmentTypes = await this.client.methods.getAppointmentTypes(this.identifier, this.practioners);
        let locationData = await this.client.methods.getLocationData(this.identifier);

        this.formFields = this.client.methods.getFormFields(); 
        this.name = locationData.name;
        this.address = locationData.address;
        this.phone = locationData.phone;
        this.postcode = locationData.postcode;
        this.email = locationData.email;
        this.state = locationData.state;
        this.bulkbill = locationData.bulkbill;
        this.suburb = locationData.suburb;

        return true;

    }
}