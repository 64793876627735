import { optomate } from '../services/optomate.js';
 
export class OpticalCo {

    constructor() {
        this.methods = optomate;
    }
    
    customStyles() {
        return ".name {padding-left:20px;}"
        + " .date_list_container:hover { border-color: #5fc2b6; }"
        ;
    }

    colorScheme() {
        return { 
            primary: '#1c1d28',
            secondary: '#1e5e72',
            secondarySelected: '#113742',
        }
    }

}