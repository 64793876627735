import React, { useEffect, useState } from 'react';
import useStateRef from "react-usestateref";
import moment from 'moment';
import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';

// Styles
import 'src/css/index.css';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from 'src/css/styles';

// Components
import StepsWrapper from 'src/components/StepsWrapper';
import TypeSelectView from 'src/Views/TypeSelectView';
import BookingView from 'src/Views/BookingView';
import ConfirmationView from 'src/Views/ConfirmationView';
import ThankyouView from './Views/ThankyouView';

// Models
import { Branch } from 'src/data/Branch';

const BookingPortal = (props) => {

  const loadingSkeleton = () => {
    return (
      <div style={{ padding: '20px 40px', marginTop: '20px' }}>
        <Skeleton variant="rounded" width={'300px'} height={30} />
        <div style={{ display: 'flex' }}>
          <Skeleton variant="rounded" width={'100px'} height={30} style={{ marginTop: "20px" }} />
          <Skeleton variant="rounded" width={'200px'} height={30} style={{ margin: "20px" }} />
        </div>
      </div>
    )
  }

  let params = new URLSearchParams(window.location.search);
  const [initialized, setInitialized] = useState(false);
  const [branch, setBranch, branchRef] = useStateRef(null);
  const [selectedStep, setSelectedStep] = useState(0);
  const [currentView, setCurrentView] = useState(loadingSkeleton());
  const [isMobile, setIsMobile, isMobileRef] = useStateRef(false);
  const [lastAppointmentType, setLastAppointmentType, lastAppointmentTypeRef] = useStateRef(null);
  const [, setWindowNumber, windowNumberRef] = useStateRef(0);
  const [, setBookingDetails, bookingDetailsRef] =
    useStateRef((params.get("test")) ?
      {
        'email': 'test@test.com',
        'DOB':  dayjs('2022-04-17'),
        'firstName': 'Test',
        'lastName': 'Patient',
        'phone': '0419830851',
        'gender': "Female",
        'visited': 'No',
      } : {
        'email': '',
        'DOB': '',
        'firstName': '',
        'lastName': '',
        'phone': '',
        'gender': 'Male',
        'visited': 'No',
      });

  // On app load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!initialized) {
      setBranch(new Branch());
      init();
      setInitialized(true);
    }
    // handleResize();
  });

  // const handleResize = () => {
  //   setIsMobile(document.getElementById('appContainer').clientWidth < 480);
  //   var height = document.getElementById('appContainer').clientHeight;
  //   if (height !== 0) window.top.postMessage(height, '*');
  // }

  const getTypeSelectView = () => {
    return (
      <TypeSelectView 
        //handleResize={handleResize}
        branch={branchRef.current} 
        handleWindowChange={handleWindowChange} 
        bookingDetails={bookingDetailsRef.current} 
        setBookingDetails={setBookingDetails} 
        lastAppointmentType={lastAppointmentTypeRef.current}
        setLastAppointmentType={setLastAppointmentType}
        isMobile={isMobileRef.current}
      />
    )
  }

  const init = async () => {
    if(document.getElementById('booking-wrapper')) setIsMobile(params.get('noMobile') ? false : document.getElementById('booking-wrapper').clientWidth < 480);
    if(params.get('test')) console.log("Is Mobile: " + isMobileRef.current);
    setCurrentView(loadingSkeleton());
    const connected = await branchRef.current.getData();
    if (!connected) {
      setCurrentView(<div className='error-page'>Error: Could not connect to the server.</div>);
      return;
    }
    setBranch(branchRef.current);
    setCurrentView(getTypeSelectView());
  }

  const handleWindowChange = async (isNext) => {

    window.top.postMessage("goTop", '*');
    
    if (isNext && windowNumberRef.current < 3) {
      setWindowNumber(windowNumberRef.current + 1);
    } else {
      setWindowNumber(windowNumberRef.current - 1);
    }

    if (windowNumberRef.current === 0) {
      setCurrentView(getTypeSelectView())
    } else if (windowNumberRef.current === 1) {
      setCurrentView(
          <BookingView 
            branch={branchRef.current} 
            handleWindowChange={handleWindowChange} 
            bookingDetails={bookingDetailsRef.current} 
            setBookingDetails={setBookingDetails} 
            isMobile={isMobileRef.current}
            />
      )
      setSelectedStep(1);
    } else if (windowNumberRef.current === 2) {
      setCurrentView(
        <ConfirmationView 
          bookingDetails={bookingDetailsRef.current} 
          branch={branchRef.current} 
          handleWindowChange={handleWindowChange}
          isMobile={isMobileRef.current}
        />
      );
      setSelectedStep(2);
    } else if (windowNumberRef.current === 3) {
      setCurrentView(
        <ThankyouView 
          loading={true}
          branch={branchRef.current} 
          bookingDetails={bookingDetailsRef.current} 
        />
      );
      let success = await sendPostRequest();
      setCurrentView(
        <ThankyouView 
          loading={false}
          branch={branchRef.current} 
          bookingDetails={bookingDetailsRef.current} 
          success={success} 
        />
      );
    }
  }

  /**
   * Sends a post request to create a new appointment and updates the UI accordingly.
   * @async
   * @function
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the post request was successful.
   */
  const sendPostRequest = async () => {
    const branch = branchRef.current;
    try {
      var success = await branch.client.methods.postAppointment({
        startdate: bookingDetailsRef.current.systemDate,
        starttime: bookingDetailsRef.current.systemTime,
        branchIdentifier: branch.identifier,
        practitionerID: bookingDetailsRef.current.doctorID,
        appointmentType: bookingDetailsRef.current.appointmentType,
        bookingDetails: bookingDetailsRef.current
      });
  
      let message = '?name=' + bookingDetailsRef.current.firstName
        + '&client_email=' + bookingDetailsRef.current.email
        + '&provider_email=' + branch.email
        + '&phone=' + branch.phone
        + '&address=' + encodeURIComponent(branch.address)
        + '&postcode=' + branch.postcode
        + '&state=' + branch.state
        + '&suburb=' + branch.suburb
        + '&provider=' + bookingDetailsRef.current.doctorName
        + '&practice_name=' + branch.name
        + '&time=' + moment(bookingDetailsRef.current.systemTime, "HH:mm").format("hh:mm A")
        + '&date=' + moment(bookingDetailsRef.current.systemDate).format("Do MMMM, YYYY")
        + '&brand=' + branch.clientName
        + '&type=' + bookingDetailsRef.current.appointmentTypeDesc;
  
      if (success && branch.client.methods.sendConfirmMessage) branch.client.methods.sendConfirmMessage(message);
      return success;
    } catch (error) {
      <ThankyouView 
          loading={false}
          branch={branch} 
          bookingDetails={bookingDetailsRef.current} 
          success={false} 
        />
        console.log(error);
    }
  }

  return (
    <div className='book-consultation-detail-wrapper'>
      <div id="appContainer" className='book-consultation-detail-content-container-outer'>
        <div className='book-consultation-detail-content-container'>
          {params.get('site') ? <div className='book-consultation-detail-content-wrapper'>
            <style>
              {branch ? branch.customStyles : ''}
            </style>
            <StepsWrapper step={selectedStep} isMobile={isMobileRef.current} />
            {currentView}
          </div> : 'Please add URL parameters to view this page.'}
        </div>
      </div>
    </div>
  );

}

const connectAll = withStyles(useStyles, { withTheme: true })(BookingPortal);
export default connectAll;